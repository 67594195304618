<template>
  <div
    class="col-lg-4 col-md-6"
    style="cursor: pointer"
    @click="m_onCurriculumClicked()"
  >
    <div class="education-block-grid style-1001">
      <div class="education-block-thumb n-shadow">
        <b-img-lazy
          height="208.77"
          width="313"
          blank-color="#eee"
          @error.native="m_replaceDefaultimg"
          :src="m_getImageURL()"
          class="img-fluid"
        />
      </div>

      <div class="education-block-body">
        <h4 class="bl-title text-center">
          <span
            >{{ p_data.en }} {{ p_education == "s" ? "Curriculum" : "" }}</span
          >
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
const l_defaultimageUrl = "/img/971TutorsImages/default.jpeg";
export default {
  name: "cmp-curriculumcard",
  props: {
    p_data: {
      type: Object,
    },
    p_education: {
      type: String,
    },
    p_id: {
      type: String,
    },
  },
  methods: {
    m_replaceDefaultimg(p_event) {
      p_event.target.src = l_defaultimageUrl;
    },
    m_getImageURL() {
      let l_url = l_defaultimageUrl;
      let l_splitLength = this.p_id.split("_").length;
      if (l_splitLength == 1) {
        l_url = "/img/971TutorsImages/curriculums/" + this.p_id + ".jpeg";
      } else if (l_splitLength > 1) {
        l_url =
          "/img/971TutorsImages/categories/" +
          this.p_id.split("_")[1] +
          ".jpeg";
      }
      return l_url;
    },
    m_onCurriculumClicked() {
      this.$store.commit("md_fire/mt_setLoading", true);
      switch (this.p_education) {
        case "s":
          this.m_onSchool();
          break;
        default:
          this.m_onUniversity();
          break;
      }
      this.$store.commit("md_fire/mt_setSubjectsEducation", this.p_education);
    },
    async m_onSchool() {
      await this.$localforage.setItem("fs_curriculum", this.p_id);
      await this.$localforage.setItem("fs_fromCurriculum", true);
      this.$store.commit("md_fire/mt_setCurriculum", this.p_id);
      this.$router.push({ path: "/subjects" });
    },
    async m_onUniversity() {
      await this.$localforage.setItem("fs_category", this.p_id);
      await this.$localforage.setItem("fs_filterCategory", this.p_id);
      await this.$localforage.setItem("fs_fromCurriculum", true);
      this.$store.commit("md_fire/mt_setCategory", this.p_id);
      this.$router.push({ path: "/subjects" });
    },
  },
};
</script>

<style>
</style>
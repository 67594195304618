<template>
  <section id="main-wrapper" style="min-height: 95vh">
    <div class="container">
      <div class="clearfix"></div>
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">Education</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-0">
        <div class="container text-center">
          <div id="main2" style="margin-bottom: 40px; min-height: 130px">
            <button
              @submit.prevent
              @click="onEducationChanged('s')"
              :class="
                d_education != 's'
                  ? 'btn btn-outline-theme btn-education-sidebar'
                  : 'btn btn-theme btn-education-sidebar'
              "
            >
              School
            </button>

            <button
              @submit.prevent
              @click="onEducationChanged('u')"
              :class="
                d_education != 'u'
                  ? 'btn btn-outline-theme btn-education-sidebar'
                  : 'btn btn-theme btn-education-sidebar'
              "
            >
              University
            </button>

            <button
              @submit.prevent
              @click="onEducationChanged('l')"
              :class="
                d_education != 'l'
                  ? 'btn btn-outline-theme btn-education-sidebar'
                  : 'btn btn-theme btn-education-sidebar'
              "
            >
              Languages
            </button>

            <button
              @submit.prevent
              @click="onEducationChanged('o')"
              :class="
                d_education != 'o'
                  ? 'btn btn-outline-theme btn-education-sidebar'
                  : 'btn btn-theme btn-education-sidebar'
              "
            >
              Others
            </button>
          </div>
          <!-- Onclick Sidebar -->
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="row" v-if="d_education == 's'">
                <cmp-curriculumcard
                  v-for="(p_value, p_key) in gt_curriculums"
                  :key="p_key"
                  :p_data="p_value"
                  p_education="s"
                  :p_id="p_key"
                />
              </div>
              <div class="row" v-else>
                <cmp-curriculumcard
                  v-for="(p_value, p_key) in gt_educationsCategories"
                  :key="p_key"
                  :p_data="p_value"
                  :p_education="d_education"
                  :p_id="p_key"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import c_curriculumCard from "../cmp-components/cmp-curriculumcard.vue";

export default {
  name: "cmp-curriculums",
  components: {
    "cmp-curriculumcard": c_curriculumCard,
  },
  computed: {
    ...mapGetters("md_fire", ["gt_curriculums", "gt_educationsCategories"]),
  },
  data() {
    return {
      d_education: "s",
      d_category: "all",
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.d_education = "s"; //this.$store.state.md_fire.st_education;
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 1250);
  },
  methods: {
    async onEducationChanged(p_education) {
      this.d_education = p_education;
      this.$store.commit("md_fire/mt_setEducation", p_education);
      await this.$localforage.setItem("fs_education", p_education);
      this.d_category = "all";
      this.$store.commit("md_fire/mt_setCategory", "all");
      await this.$localforage.setItem("fs_category", "all");
    },
  },
};
</script>

<style lang="css" scoped>
.btn-education-sidebar {
  width: 136px;
  height: 51.59px;
  margin: 2px;
}
</style>